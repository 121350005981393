export interface RetryOptions {
  /**
   * Number of retries.
   *
   * Total number of action calls will be `times` + 1.
   */
  times: number;
  /**
   * Delay of first rety.
   *
   * Subsequent retries are delayed by double the last delay time.
   */
  delay: number;
  /**
   * Backoff rate. `delay` is multiplied by this value on each iteration.
   *
   * @default 2.
   */
  backoff?: number;
  /**
   * Code to run after a failure.
   */
  refresh?(): void | Promise<void>;
  /**
   * Cancel retry cycle.
   */
  cancelRetryIf?(error: unknown): boolean;
}

export async function retry<T>(
  { times, delay, refresh, backoff, cancelRetryIf }: RetryOptions,
  action: () => Promise<T>,
): Promise<T> {
  const _cancelRetryIf = cancelRetryIf ?? ((_: unknown) => false);
  const _backoff = backoff ?? 2;
  for (;;) {
    try {
      return await action();
    } catch (e) {
      if (--times < 0 || _cancelRetryIf(e)) {
        throw e;
      }
      const currentTimeout = delay;
      await refresh?.();
      await new Promise((resolve) => setTimeout(resolve, currentTimeout));
      delay *= _backoff;
    }
  }
}
